<template>
  <div class="container my-5">
    <h1>Presentazione</h1>
    <p v-html="ente.testo_presentazione"></p>

  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
      ...mapState([
          'ente'
      ]),
  },    
}
</script>